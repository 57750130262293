<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      v-if="visible"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="供应商" prop="orgId">
          <el-select
            v-model="dataForm.orgId"
            :disabled="disabled"
            @change="getPeriodList()"
          >
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-radio-group
            v-model="dataForm.isGroup"
            @change="changeGroup"
            :disabled="disabled"
          >
            <el-radio :label="true">团餐柜</el-radio>
            <el-radio :label="false">普通柜</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="取餐柜" prop="freezerId">
          <el-select
            v-model="dataForm.freezerId"
            :disabled="disabled"
            :multiple="!dataForm.id"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in freezerList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="星期" prop="weeks" class="fullList">
          <el-checkbox-group
            :disabled="disabled"
            v-model="dataForm.weeks"
            class="checkBtnColor"
            size="medium"
          >
            <el-checkbox-button
              v-for="(item, index) in weeksList"
              :key="index"
              :label="item.id"
              >{{ item.week }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="时段" prop="orgPeriodId">
          <el-select
            v-model="dataForm.orgPeriodId"
            :disabled="disabled"
            filterable
            clearable
            :multiple="!dataForm.id"
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.periodWithTimeName"
              :value="item.orgPeriodId"
            />
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="!disabled"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getOrgList, getFreezerList } from '@/utils/options.js';
import { dialogMixin } from '@/mixins';
import { keys, pick } from 'lodash';

export default {
  mixins: [dialogMixin],
  data() {
    return {
      disabled: false,
      time: [],
      startTime: '',
      endTime: '',
      dataForm: {
        id: '',
        orgId: '',
        freezerId: [],
        orgPeriodId: [],
        startDate: '',
        endDate: '',
        isGroup: true,
        weeks: [1, 2, 3, 4, 5, 6, 7],
        periodWithTimeName: '',
      },
      orgList: [],
      freezerList: [],
      periodList: [],
      dataListSelections: [],
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
      dataRule: {
        orgId: [{ required: true, message: '供应商不能为空', trigger: 'blur' }],
        freezerId: [
          { required: true, message: '取餐柜不能为空', trigger: 'blur' },
        ],
        orgPeriodId: [
          { required: true, message: '时段不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    init(id, disabled) {
      this.$init({
        before: () => {
          this.getOrgList();
          this.getPeriodList();
          this.dataForm.id = id || '';
          this.periodList = [];
          this.disabled = disabled;
          this.$forceUpdate();
          this.dataForm.isGroup = true;
          this.getFreezerList();
          this.dataForm.weeks = [1, 2, 3, 4, 5, 6, 7];
        },
        url: id ? `/tc/orgPeriodFreezer/info/${id}` : null,
        after: (data) => {
          this.dataForm = pick(data.orgPeriodFreezer, keys(this.dataForm));
          this.dataForm.weeks = data.orgPeriodFreezer.weeks
            ? data.orgPeriodFreezer.weeks
                .split(',')
                .map((item) => parseInt(item))
            : [];
          this.getPeriodList();
          this.getFreezerList();
        },
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    getFreezerList() {
      getFreezerList(1, this.dataForm.isGroup).then(({ data }) => {
        if (data && data.status === 0) {
          this.freezerList = data.data.items;
        }
      });
    },
    //
    selectionChange(val) {
      this.dataListSelections = val;
    },
    getPeriodList() {
      if (this.dataForm.orgId) {
        this.$http({
          url: `/tc/orgperiod/combo/${this.dataForm.orgId}`,
          method: 'get',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.periodList = data.list;
            this.time = this.periodList.filter(
              (item) => item.id === this.dataForm.orgPeriodId[0],
            );
          }
        });
      }
    },
    changeGroup() {
      this.dataForm.freezerId = [];
      this.getFreezerList();
    },
    // 表单提交
    dataFormSubmit() {
      if (this.dataForm.id) {
        this.periodList.find((item) => {
          if (item.orgPeriodId === this.dataForm.orgPeriodId) {
            this.dataForm.startDate = item.startDate;
            this.dataForm.endDate = item.endDate;
          }
        });
      }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/orgPeriodFreezer/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: {
              id: this.dataForm.id,
              orgId: this.dataForm.orgId,
              weeks: this.dataForm.weeks.map((i) => i).join(','),
              orgPeriodIds: this.dataForm.id
                ? this.dataForm.orgPeriodId
                : this.dataForm.orgPeriodId.map((i) => i).join(','),
              freezerIds: this.dataForm.id
                ? this.dataForm.freezerId
                : this.dataForm.freezerId.map((i) => i).join(','),
              isGroup: this.dataForm.isGroup,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.code === 422) {
              this.$message({
                message: data.errors.periodId,
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.inputNumberInside {
  width: 100%;
  .el-input__inner {
    border: none;
  }
}
.tableLine {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 0 8px;
  .leftLine {
    font-size: 12px;
    width: 160px;
    padding: 0 10px 0 0;
    text-align: left;
  }
  .rightLine {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    .longTime {
      width: 100%;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .el-input__icon {
        line-height: 30px;
      }
    }
    .leftname {
      width: 90px;
      text-align: center;
      font-size: 12px;
      color: #000;
      line-height: 30px;
    }
    .halfTime {
      width: auto;
      flex: 1;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .el-input__icon {
        line-height: 30px;
      }
    }
  }
}

.newTablePart {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  .newTable {
    width: 70%;
    position: relative;
  }
  .firstTable {
    width: 30%;
    position: relative;
  }
}
</style>
